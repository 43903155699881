import requestService from "../requestService";
import store from '@/store';

export default {
    async getTripsStatistic() {
        const response = await requestService.get(`/cp_dash/statistic/route_schedule_and_tickets`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getTrips(page, search, sortBy, sortDesc) {
        const response = await requestService.get(`/cp_dash/schedule?page=${page}&search=${search}&order=${sortBy}&direction=${sortDesc}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getDeletedTrips() {
        const response = await requestService.get(`/cp_dash/schedule/deleted`)
        return response?.data
    },
    async getTripsByStatus(status, page) {
        const response = await requestService.get(`/cp_dash/schedule/${status}?page=${page}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getTripById(id) {
        const response = await requestService.get(`/cp_dash/schedule/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getTripsByRouteID(id) {
        const response = await requestService.get(`/cp_dash/schedule/route/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getTripByIdAndCity(id, departure_city, arrival_city) {
        const response = await requestService.get(`/schedule/${id}/${departure_city}/${arrival_city}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async createTrip(form) {
        const response = await requestService.post(`/cp_dash/schedule`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async updateTrip(id, form) {
        const response = await requestService.post(`/cp_dash/schedule/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async changeTripStatus(id, form) {
        const response = await requestService.post(`/cp_dash/schedule/status/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async restoreTrip(id, form) {
        const response = await requestService.post(`/cp_dash/schedule/restore/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async deleteTrip(id) {
        const response = await requestService.delete(`/cp_dash/schedule/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    }
}