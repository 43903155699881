<template>
  <v-btn :disabled="disabled" text class="cancelBtn" @click="$emit('click')">
    <span>{{ text }}</span>
  </v-btn>
</template>
  
  <script>
export default {
  props: {
    text: {
      require: true,
    },
    disabled: {
      require: true,
    },
  },
};
</script>
  
  <style scoped>
.cancelBtn {
  color: #4b5262;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
  padding: 8px !important;
}
</style>